import Header from './HeaderComponenet';
import React, { Component } from 'react';
import Home from './HomeComponent';
import Portal from './PortalComponent';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import Citation from './CitationComponent';
import Report from './ReportComponent';


class Main extends Component {
    render() {
        const HomePage =() => {
            return (
                <Home />
            );
        }
        return (
            <div>             
                <Header/>
                <Switch>
                    <Route path='/home' component={HomePage} /> 
                    <Route path='/portal' component={Portal} /> 
                    <Route path='/citation' component={Citation} />
                    <Route path='/report' component={Report} />
                    <Redirect to='/home' />                 
                </Switch>                    
                             
            </div> 
        );
    };
}

export default Main;

